@import "../../../node_modules/react-image-lightbox/style.css";

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReactModal__Overlay {
  /* Fix default z-index of 1000 */
  z-index: 2000 !important;
}

.ril__toolbarItem {
  /* Style fix for Lightbox toolbar buttons */
  max-height: 50px;
}

.dropzone {
  cursor: pointer;

  &.dropzone--disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
}

.form-control.is-invalid + .form-control-loading, .form-control.is-valid + .form-control-loading {
  right: 35px;
}

.loading-form-field-container {
  position: relative;
  width: 100%;
}

.form-inline .loading-form-field-container {
  width: auto;
}

.form-control-loading {
  width: 20px;
  height: 20px;

  display: block;
  position: absolute;

  right: 10px;
  top: 50%;

  transform: translateY(-50%);

  .spinner-border {
    width: 100%;
    height: 100%;

    color: #bfc0c4;
  }
}

.border-bottom.border-bottom-6 {
  border-bottom-width: 6px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.home-page-button-box {
  i {
    position: absolute;
    left: 0;
    bottom: -15px;
    font-size: 4rem;
    opacity: 0.4;
    transform: rotate(30deg);
  }
}

// react-datepicker
.react-datepicker-wrapper {
  //max-width: 158px;

  width: 100%;

  .form-control {
    padding: 0.45rem 0.45rem;
  }
}

.react-datepicker__month-text:hover {
  background-color: $secondary !important;
}

.react-datepicker__month-text--disabled {
  color: $gray-100 !important;
  cursor: not-allowed;
}

.form-inline .react-datepicker-wrapper {
  display: inline-block;
  max-width: 158px;
}

.react-datepicker-popper {
  z-index: 200;
}

// react-select
.react-select-container {
  width: 100%;
  display: block;
}

.react-select-container .react-select__control {
  border-color: #dee2e6;
}

.form-inline .react-select-container {
  display: inline-block;
  width: auto;
  min-width: 300px;
}

.is-invalid > .react-select__control {
  border-color: $form-feedback-invalid-color;
  box-shadow: none;

  &:hover {
    border-color: $form-feedback-invalid-color;
  }
;

  &:focus {
    border-color: $form-feedback-invalid-color;
  }
;
}

.react-select__control .react-select__placeholder {
  color: $input-placeholder-color;
  // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
  opacity: 1;
}

.react-select--is-disabled > .react-select__control {
  background-color: #e9ecef !important;
}

body {
  position: relative;
}

.list-link {
  display: block;
  color: inherit !important;
  text-align: initial;
  margin: 0 !important;
  padding: 0 !important;

  &:hover, &.active {
    background-color: $light;
  }
}

ul.check-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;

    margin: 0;
    padding: 0;
    text-indent: 0;

    padding-left: 1.2em;
  }

  li.uit-te-voeren.uitgevoerd:before {
    content: "\F05E0"; /* .mdi-check-circle */
    color: $success;
  }

  li:not(.uit-te-voeren).uitgevoerd:before {
    content: "\F05E0"; /* .mdi-check-circle */
    color: $danger;
  }

  li.uit-te-voeren:not(.uitgevoerd):before {
    content: "\F0156"; /* .mdi-close */
    color: $danger;
  }

  li:not(.uit-te-voeren):not(.uitgevoerd) {
    display:none;
  }

  li:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** Split pane */
.splitpane-splitter {
  margin-left: 0.5rem;
  margin-right: -6px;

  padding-left: 10px;
  padding-right: 10px;
  border: none !important;
  background-color: transparent !important;

  position: relative;

  .handle-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 100px;

    display: flex;
    justify-content: center;

    .handle-left {
      background-color: $gray-300;
      width: 1px;
      height: 100%;

      margin-right: 4px;
    }

    .handle-right {
      background-color: $gray-300;
      width: 1px;
      height: 100%;
    }
  }

  transition: color 0.2s linear 0s, background-position 0.2s linear 0s, background-size 0.2s linear 0s, background 0.2s linear 0s !important;
  background-image: radial-gradient(at center center, rgba(0, 0, 0, 0.2) 0%, transparent 70%, transparent 100%);
  background-size: 50px 100%;
  background-position: 20px 50%;
  background-repeat: no-repeat;

  &:hover {
    background-image: radial-gradient(at center center, rgba(0, 0, 0, 0.2) 0%, transparent 70%, transparent 100%);
    background-size: 50px 100%;
    background-position: 10px 50%;
    background-repeat: no-repeat;

    transition: color 0.2s linear 0s, background-position 0.2s linear 0s, background-size 0.2s linear 0s, background 0.2s linear 0s !important;
  }
}

.planning-entry-drag-handle {
  transition: background 0.2s linear 0s;

  &:hover {
    transition: background 0.2s linear 0s;

    background-color: rgba(179, 179, 179, 0.08);
  }
}

.border-radius-circle {
  border-radius: 50%;
}

/** Timeline */
.dagplanning-timeline {
  margin-bottom: 50px;
  position: relative;

  .end-dot {
    width: 6px;
    position: absolute;
    bottom: calc(0.75rem - 5px);
    left: 12px;
    height: 6px;
    background-color: $gray-300;
    border-radius: 50%;
  }

  &:before {
    background-color: $gray-300;
    bottom: 0.75rem;
    content: "";
    left: 14px;
    position: absolute;
    top: 0.75rem;
    width: 2px;
    z-index: 0;
  }

  .timeline-item {
    padding-left: 50px;
    margin-bottom: 1em;

    position: relative;

    .icon {
      color: $gray-600;
      background-color: $gray-300;
      padding: 4px;
      border-radius: 50%;

      width: 30px;
      height: 30px;

      font-size: 18px;
      line-height: 18px;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      left: 0;
      //top: 50%;
      //transform: translateY(-50%);
      top: 0.75rem;
    }

    .planning-bezoek-actions {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      visibility: hidden;

      transition: visibility 0s, opacity 0.3s linear 0s;
    }

    &:hover {
      .planning-bezoek-actions {
        opacity: 1;
        visibility: visible;

        position: absolute;

        display: flex;

        background-color: rgba(255, 255, 255, 0.6);

        transition: visibility 0s, opacity 0.3s linear 0s;
      }
    }
  }
}

.dagplanning-bezoek-card {
  position: relative;

  .info-panel {
    @extend .shadow-sm;

    //position: absolute;
    //left: 0;
    //right: 0;
    //top: 100%;

    //z-index: 3;

    background-color: white;
    padding: 0.5rem 1rem;

    //margin-left: 2rem;
    //margin-right: 2em;
  }

  &--voorstel {
    border: 2px dashed $warning;
  }
}

.dagplanning-automatisch-aanvullen {
  border: 2px dashed #dfdfdf;
  border-radius: 0.25rem;
}

.dagplanning-datum-selector {
  min-width: 10rem;
}

.opacity-0 {
  opacity: 0;

  &--hover {
    &:hover {
      opacity: 0;
    }
  }
}

.opacity-100 {
  opacity: 1;

  &--hover {
    &:hover {
      opacity: 1;
    }
  }
}

/** Editable text input */
.editable-text {
  @extend .form-control;
  @extend .text-wrap;

  height: auto;

  background-color: transparent;
  border-color: transparent;

  &:hover {
    border-color: $border-color;
  }
}

.dropzone.dropzone--small {
  min-height: auto;

  .dz-message {
    padding: 1rem;
  }
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    color: inherit;
  }
}

.td-filtered {
  position: relative;

  &:after {
    content: "";
    position: absolute;

    opacity: 0.4;
    pointer-events: none;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #000;
  }
}

.table-layout-fixed {
  table-layout: fixed;
}

.table-layout-fixed-wrapper {
  table {
    @extend .table-layout-fixed;
  }
}

.table-bordered {
  border: none; /* Fix double outer border */
}

.table {
  .sticky-column {
    position: sticky;
    left: 0;
    background-color: #fff;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      bottom: 0;
      width: 10px;
      opacity: 0;
      transition: opacity 0.1s;
      z-index: 5;

      background: linear-gradient(to left, rgba(black, 0), rgba(black, 0.1));
    }

    &--scrolling {
      //box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

      &::after {
        opacity: 1;
      }
    }
  }

  .sticky-row {
    position: sticky;
    top: 0;
    z-index: 5;
  }
}

.multi-select-scrollable-values {
  .react-select__value-container.react-select__value-container--is-multi {
    max-height: calc(2.25rem + 2px);
    overflow: auto;
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

/** Scrollbar */
.simplebar-scrollbar::before {
  background: #888888 !important;
}

.row-expansion-style {
  display: flex;
  padding: 0 !important;
  margin-top: -1px;
}

.expanded-row {
  flex: 1;
  box-shadow:
          inset 0 15px 15px -20px rgba(0, 0, 0, 0.4),
          inset 0 -15px 15px -20px rgba(0, 0, 0, 0.4);

  &:hover {
    background-color: initial;
  }

  thead tr {
    &:hover {
      color: initial !important;
      background-color: initial !important;
    }

    th {
      border-top: none !important;
    }
  }
}

.expanded-row-parent + tr {
  &:hover {
    color: initial !important;
    background-color: initial !important;
  }
}

.overflow-visible {
  overflow: visible !important;
}

.tijdsduur-form-field {

  .form-control {
    input {
      outline: none;
    }

    ///* Chrome, Safari, Edge, Opera */
    //input::-webkit-outer-spin-button,
    //input::-webkit-inner-spin-button {
    //  -webkit-appearance: none;
    //  margin: 0;
    //}
    //
    ///* Firefox */
    //input[type=number] {
    //  -moz-appearance: textfield;
    //}
  }
}

.table-fixed-header {
  table {
    position: relative;

    thead {
      position: sticky;
      top: 0;

      background-color: #f1f3fa;
      z-index: 900;
    }
  }
}

.weekoverzicht-table {
  height: 65vh;
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected) {
  color: $gray-700;
}

.react-datepicker__day--disabled {
  color: $text-muted;
}
